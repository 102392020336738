<template>
  <div>
    <v-row v-if="expert.isContactsShown">
      <v-col>
        <h3>{{ $t("Expert info") }}</h3>
        <v-divider class="mb-4" />
      </v-col>
    </v-row>
    <v-row v-if="expert.isContactsShown">
      <v-col>
        <v-skeleton-loader transition="scale-transition" :loading="loading" type="paragraph">
          <table>
            <tr v-if="expert.skype !== null">
              <td>{{ $t("Skype") }}:</td>
              <td>
                <a :href="`skype:${expert.skype}?chat`">
                  {{ expert.skype }}
                </a>
              </td>
            </tr>
            <tr v-if="expert.phone !== null">
              <td>{{ $t("Phone") }}:</td>
              <td>
                <a :href="`tel:${expert.phone}`">
                  {{ expert.phone }}
                </a>
              </td>
            </tr>
            <tr v-if="expert.email !== null">
              <td>{{ $t("Email") }}:</td>
              <td>
                <a :href="`mailto:${expert.email}`">
                  {{ expert.email }}
                </a>
              </td>
            </tr>
          </table>
        </v-skeleton-loader>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <h3>{{ $t("Experience") }}</h3>
        <v-divider class="mb-4" />
      </v-col>
    </v-row>
    <v-skeleton-loader transition="scale-transition" :loading="loading" type="table-row">
      <v-row>
        <v-col v-for="spec in expert.specializations" :key="spec.title">
          <v-checkbox
            :label="spec.specialism | startCase"
            readonly
            :value="spec.specialism"
            v-model="spec.specialism"
          />
          <p>
            <v-chip color="white" class="chip-shadow pa-4 mx-2" v-if="isAuthorised">
              {{ spec.work_experience | startCase }}
            </v-chip>
          </p>
          <p>
            <v-chip color="white" class="chip-shadow pa-4 mx-2">${{ spec.hourly_rate }}/h</v-chip>
          </p>
        </v-col>
      </v-row>
    </v-skeleton-loader>

    <v-row v-if="isAuthorised">
      <v-col>
        <h3>{{ $t("Professional Certificates") }}</h3>
        <v-divider class="mb-4" />
      </v-col>
    </v-row>
    <v-row v-if="isAuthorised">
      <v-skeleton-loader transition="scale-transition" :loading="loading" type="list-item">
        <v-list color="transparent" class="pa-0">
          <v-list-item v-for="license in expert.licenses" :key="license.name">
            <v-list-item-content>
              {{ license.title }}
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-skeleton-loader>
    </v-row>
  </div>
</template>

<script>
import { getAllUserSpecializations } from "@/api";
import { map, keys, indexOf, startCase } from "lodash";
import { me } from "@/auth/auth";

export default {
  props: {
    expert: {
      type: Object
    }
  },
  components: {},
  data: function() {
    return {
      loading: true,
      isAuthorised: me() || false,
      specExpertItems: [],
      workExperienceItems: []
    };
  },
  methods: {
    getSpecialisations() {
      getAllUserSpecializations()
        .then(r => {
          this.specExpertItems = map(r.data["expert_specializations"], (id, title) => ({
            specialism: id,
            text: startCase(title),
            title: title,
            value: "",
            show: indexOf(this.expert.specializations, title)
          }));
          this.workExperienceItems = keys(r.data["work_experiences"]);
          this.loading = false;
        })
        .catch(err => {
          console.error(err);
        });
    }
  },
  mounted() {
    this.getSpecialisations();
  }
};
</script>
